import React from 'react';
import Styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Heading, Text, Link } from 'rebass';
import DynamicLink from './elements/DynamicLink';
import baseTheme from '../themes/baseTheme';
import colorPalette from '../themes/colorPalette';

// base theme:
// space: [0, 4, 8, 16, 32, 64, 128],
// fontSizes: [12, 14, 16, 20, 24, 36, 48],

export const H1 = (props) => (
  <Heading {...props} as="h1">
    {props.children}
  </Heading>
);

export const H2 = (props) => (
  <Heading {...props} as="h2">
    {props.children}
  </Heading>
);

export const H3 = (props) => (
  <Heading {...props} as="h3">
    {props.children}
  </Heading>
);

export const H4 = (props) => (
  <Heading {...props} as="h4">
    {props.children}
  </Heading>
);

export const Display2 = Styled(H1)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[6]}px;
line-height: 60px;
font-weight: 400;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
padding: 0;
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};
`;

export const Display1 = Styled(H1)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[5]}px;
line-height:  44px;
font-weight: 400;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
padding: 0;
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};

`;

export const Headline = Styled(H2)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[4]}px;
line-height: 32px;
font-weight: 400;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};
margin-bottom: 16px;
`;

export const Title = Styled(H3)`

font-size: 18px;
line-height:  30px;
font-weight: 400;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};
`;

export const Subheading = Styled(H4)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[2]}px;
line-height: 28px;
font-weight: 400;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};
`;

export const Body2 = Styled(Text)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[2]}px;
line-height: 28px;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};
margin-block-start: 0em;
margin-block-end: 1em;
&.m0 {
  margin: 0;
}
`;

export const Body1 = Styled(Text)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[1]}px;
line-height: 24px;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
margin-block-start: 1em;
margin-block-end: 1em;
margin-bottom: 1em;
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};
`;

export const Caption = Styled(Text)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[0]}px;
line-height: 20px;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};
`;

export const NavigationLink = Styled(Link)`
  font-size: ${({ theme = baseTheme }) => theme.fontSizes[1]}px;
  margin: 0;
  font-family: BasisMedium, Helvetica, Ariel, sans-serif;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme = baseTheme }) => theme.colors.primaryFont};

  &:hover {
    color: ${({ theme = baseTheme }) => theme.colors.linkFont};
    box-shadow: none;
    text-decoration: none;
  }
`;

export const TopNavLink = Styled(NavLink)`
  font-size: ${({ theme = baseTheme }) => theme.fontSizes[1]}px;
  margin: 0;
  font-family: BasisMedium, Helvetica, Ariel, sans-serif;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme = baseTheme }) => theme.colors.primaryFont};

  &:hover {
    color: ${({ theme = baseTheme }) => theme.colors.linkFont};
    box-shadow: none;
    text-decoration: none;
  }
`;

export const TopNavLinkExternal = Styled.a`
  font-size: ${({ theme = baseTheme }) => theme.fontSizes[1]}px;
  margin: 0;
  font-family: BasisMedium, Helvetica, Ariel, sans-serif;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme = baseTheme }) => theme.colors.primaryFont};

  &:hover {
    color: ${({ theme = baseTheme }) => theme.colors.linkFont};
    box-shadow: none;
    text-decoration: none;
  }
`;

export const DropdownGroupHeader = Styled(Text)`
  font-size: ${({ theme = baseTheme }) => theme.fontSizes[1]}px;
  margin: 0;
  font-family: BasisMedium, Helvetica, Ariel, sans-serif;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme = baseTheme }) => theme.colors.primaryFont};

  &:hover {
    color: ${({ theme = baseTheme }) => theme.colors.linkFont};
    box-shadow: none;
    text-decoration: none;
  }
  `;

export const DropdownGroupHeaderSingle = Styled(DynamicLink)`
  font-size: ${({ theme = baseTheme }) => theme.fontSizes[1]}px;
  margin: 0;
  font-family: BasisMedium, Helvetica, Ariel, sans-serif;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme = baseTheme }) => theme.colors.primaryFont};

  &:hover {
    color: ${({ theme = baseTheme }) => theme.colors.linkFont};
    box-shadow: none;
    text-decoration: none;
  }
  `;

export const TextLink = Styled(DynamicLink)`
color: ${({ theme = baseTheme }) => theme.colors.linkFont};

line-height: 20px;
&:hover {
  color: ${({ theme = baseTheme }) => theme.colors.linkFont};
  text-decoration: none;
}
font-family: BasisMedium, Helvetica, Ariel, sans-serif;

`;
export const TextLinkDisabled = Styled(Text)`
color: ${({ theme = baseTheme }) => theme.colors.linkFont};
cursor: pointer;
line-height: 20px;
&:hover {
  color: ${({ theme = baseTheme }) => theme.colors.linkFont};
  text-decoration: none;
}
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
`;

export const TextLinkStyle = Styled(Link)`
color: ${({ theme = baseTheme }) => theme.colors.primaryFont};
&:hover {
  color: ${({ theme = baseTheme }) => theme.colors.linkFont};
  text-decoration: none;
}
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
`;

export const ButtonFont = Styled(Text)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[2]}px;
font-weight: 400;
line-height: 14px;
font-family: BasisMedium, Helvetica, Ariel, sans-serif;
padding: ${({ theme = baseTheme }) => theme.space[0]}px ${({ theme = baseTheme }) => theme.space[2]}px;
height: auto;

`;

export const BlockQuote = Styled.blockquote`

border-left: ${({ theme = baseTheme }) => theme.border};
font-weight: normal;
margin-left: 0;
padding-left: 32px;
border-width: 4px;
border-color: ${colorPalette.colors.indigos[4]};
& div:last-child {
  margin-bottom: 0;
}
`;
export const BadgeFont = Styled(Text)`

font-size: ${({ theme = baseTheme }) => theme.fontSizes[0]}px;
font-weight: 500;
line-height: 12px;
font-family: BasisMono;
padding: 2px ${({ theme = baseTheme }) => theme.space[1]}px 3px ${({ theme = baseTheme }) => theme.space[1]}px ;
height: auto;
text-transform: uppercase;
vertical-align: baseline;
`;

// Markdown extended components
