import Loadable from 'react-loadable';
import { loader } from '../functions';

const SimpleTextBlock = Loadable({
      loader: () => import('./patterns/SimpleTextBlock'),
      loading: () => loader
});

const DonateBlock = Loadable({
      loader: () => import('./patterns/DonateBlock'),
      loading: () => loader
});

const Form = Loadable({
      loader: () => import('./patterns/Form'),
      loading: () => loader
});

const TextBlockList = Loadable({
      loader: () => import('./patterns/TextBlockList'),
      loading: () => loader
});

const TabItem = Loadable({
      loader: () => import('./patterns/TabItem'),
      loading: () => loader
});
const TabbedCarousel = Loadable({
      loader: () => import('./patterns/TabbedCarousel'),
      loading: () => loader
});

const Megamenu = Loadable({
      loader: () => import('./patterns/Megamenu'),
      loading: () => loader
});

const IntroAnimation = Loadable({
      loader: () => import('./patterns/IntroAnimation'),
      loading: () => loader
});

const CenteredBlock = Loadable({
      loader: () => import('./patterns/CenteredBlock'),
      loading: () => loader
});

const DoubleTextBlock = Loadable({
      loader: () => import('./patterns/DoubleTextBlock'),
      loading: () => loader
});
const ImageCardList = Loadable({
      loader: () => import('./patterns/ImageCardList'),
      loading: () => loader
});

const CardList = Loadable({
      loader: () => import('./patterns/CardList'),
      loading: () => loader
});

const AdvisorList = Loadable({
      loader: () => import('./patterns/AdvisorList'),
      loading: () => loader
});

const AccordionList = Loadable({
      loader: () => import('./patterns/AccordionList'),
      loading: () => loader
});

const CtaCard = Loadable({
      loader: () => import('./patterns/CtaCard'),
      loading: () => loader
});

const IconList = Loadable({
      loader: () => import('./patterns/IconList'),
      loading: () => loader
});
const TeamList = Loadable({
      loader: () => import('./patterns/TeamList'),
      loading: () => loader
});

const TabsBlock = Loadable({
      loader: () => import('./patterns/TabsBlock'),
      loading: () => loader
});

const BlockQuote = Loadable({
      loader: () => import('./patterns/BlockQuote'),
      loading: () => loader
});
const HorizontalDeviceList = Loadable({
      loader: () => import('./patterns/HorizontalDeviceList'),
      loading: () => loader
});

const RotatingSlider = Loadable({
      loader: () => import('./patterns/RotatingSlider'),
      loading: () => loader
});

const Banner = Loadable({
      loader: () => import('./elements/Banner'),
      loading: () => loader
});

const PressList = Loadable({
      loader: () => import('./patterns/PressList'),
      loading: () => loader
});

const Checklist = Loadable({
      loader: () => import('./patterns/Checklist'),
      loading: () => loader
});

const PrimaryTextBlock = Loadable({
      loader: () => import('./patterns/PrimaryTextBlock'),
      loading: () => loader
});

const HeroBlock = Loadable({
      loader: () => import('./patterns/HeroBlock'),
      loading: () => loader
});

const DeviceList = Loadable({
      loader: () => import('./patterns/DeviceList'),
      loading: () => loader
});
const JobPost = Loadable({
      loader: () => import('./patterns/JobPost'),
      loading: () => loader
});
const JobsList = Loadable({
      loader: () => import('./patterns/JobsList'),
      loading: () => loader
});

const BlogList = Loadable({
      loader: () => import('./patterns/BlogList'),
      loading: () => loader
});

const BlogIframe = Loadable({
      loader: () => import('./patterns/BlogIframe'),
      loading: () => loader
});

const BlogYoutube = Loadable({
      loader: () => import('./elements/BlogYoutube'),
      loading: () => loader
});

export default {
      DeviceList,
      HeroBlock,
      ImageCardList,
      Banner,
      PressList,
      TabItem,
      TabsBlock,
      Checklist,
      BlockQuote,
      PrimaryTextBlock,
      TeamList,
      AdvisorList,
      HorizontalDeviceList,
      RotatingSlider,
      AccordionList,
      CtaCard,
      IconList,
      CardList,
      DoubleTextBlock,
      IntroAnimation,
      CenteredBlock,
      Megamenu,
      JobsList,
      JobPost,
      Form,
      TextBlockList,
      SimpleTextBlock,
      TabbedCarousel,
      BlogList,
      DonateBlock,
      BlogIframe,
      BlogYoutube
};
