export default {
  breakpoints: ['512px', '768px', '1024px', '1280px'],
  space: [0, 4, 8, 16, 24, 32, 64, 128],
  fontSizes: [12, 14, 16, 20, 24, 36, 48],
  weights: [400],
  colors: {
    black: '#000',
    superBlack: '#00000',
    white: '#fff',
    blue: '#40EBF9',
    peach: '#fcce9f',
    coral: '#f9706b',
    orange: '#f95f3b',
    green: '#4ce791',
    lightestGrey: '#f9f9f9',
    lightGrey: '#EDEDED',
    mediumGrey: '#979797',
    darkGrey: '#606060',
    lightPurple: '#DCE0F9',
    mediumPurple: '#6582FF',
    darkPurple: '#271B46',
    background: '#ffffff',
    primaryFont: '#4f6a92',
    linkFont: '#6582FF',
    buttonColor: '#f9706b',
    primaryTextSubdued: '#7E98C3',
    newBlue: '#40EBF9'
  },
  radius: 4,
  gridSize: 1200,
  layoutSpacingSmall: 16,
  font: 'BasisMedium, BlinkMacSystemFont, sans-serif',
  monospace: '"SF Mono", "Roboto Mono", Menlo, monospace',
  border: 'solid 1px #ECECED',
  borderNav: 'solid 2px #6582FF',
  transition: 'all .2s ease-out',
  boxShadow: '0 6px 10px 4px rgba(60,64,67,0.08)',
  phone: `(max-width: 512px)`,
  tablet: `(max-width: 767px)`,
  laptop: `(max-width: 1024px)`,
  desktop: `(min-width: 1280px)`
};
