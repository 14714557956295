import React, { Component } from 'react';
import { Flex, Image } from 'rebass';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import baseTheme from '../../themes/baseTheme';
import ProgImage from './ProgImage';

const AvatarWrapper = Styled(Flex)`
  border: ${baseTheme.border}
  max-height: ${props => props.width}
  max-width: ${props => props.height}
  border-radius: 100%;
  overflow: hidden;

`;

const AvatarImage = Styled.img`

width: 100%;
max-height: 100%;
max-width: 100%;
`;

// 128: xlarge, 96 large, 72 medium, 40 small.
class Avatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: ''
    };
  }

  componentDidMount() {
    const sizeMap = {
      xxlarge: '200px',
      xlarge: '128px',
      large: '96px',
      medium: '72px',
      small: '40px',
      xsmall: '24px'
    };
    this.setState({ size: sizeMap[this.props.avatarSize] });
  }

  render() {
    return (
      <AvatarWrapper width={this.state.size} height={this.state.size} alignItems="center" justifyContent="center">
        <AvatarImage className="Avatar" width={1} h={1} alt={this.props.alt} src={this.props.avatarImageUrl} />
      </AvatarWrapper>
    );
  }
}
Avatar.propTypes = {
  avatarSize: PropTypes.string,
  avatarImageUrl: PropTypes.string,
  avatarAltTag: PropTypes.string
};

Avatar.defaultProps = {
  avatarSize: 'medium',
  avatarImageUrl: 'https://tidepool.org/wp-content/uploads/2015/11/HowardLook-220x220.jpg',
  avatarAltTag: ''
};

/** @component */
export default Avatar;
