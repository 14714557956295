import React from 'react';
import Styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button as Base } from 'rebass';
import baseTheme from '../../themes/baseTheme';
import { BadgeFont } from '../FontStyles';

const BadgeComponent = Styled(Base)`
border-radius: ${baseTheme.radius}px;
margin: ${baseTheme.space[2]}px;
margin-top: ${props => (props.badgeMargin === '0' ? '4' : baseTheme.space[2])}px;
margin-bottom: ${props => (props.badgeMargin === '0' ? baseTheme.space[0] : baseTheme.space[2])}px;
background-color: ${baseTheme.colors.mediumPurple}
padding: 0;
height: 16px;

display: inline-flex;

&:hover {
  border: none;
  color: ${baseTheme.colors.mediumPurple};
}

&:focus {
  box-shadow: none;
}
`;

class Badge extends React.PureComponent {
  render() {
    return (
      <BadgeComponent
        {...this.props}
        badgeMargin={this.props.badgeMargin}
        className={this.props.className}
        onClick={this.props.onClick}
      >
        <BadgeFont color={this.props.color ? this.props.color : baseTheme.colors.darkPurple}>
          {this.props.text} {this.props.children}
        </BadgeFont>
      </BadgeComponent>
    );
  }
}

Badge.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

Badge.defaultProps = {
  text: '',
  className: ''
};

/** @component */
export default Badge;
