import React from 'react';
import { Flex } from 'rebass';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import baseTheme from '../../themes/baseTheme';
import Badge from './Badge';
import Renderers from '../../Renderers';

const BannerTextMarkdown = Styled(Markdown)`
color: #fff;
font-size: ${baseTheme.fontSizes[0]}px;
margin: 0;
line-height: 12px;
padding-top: 2px;
  color:  ${(props) => (props.subdomain === 'provider' ? `${baseTheme.colors.darkPurple} !important` : '#fff')};
&:hover  {
  font-size: 12px;
  line-height: 12px;
}


`;

const BadgeCont = Styled(Flex)`

@media ${baseTheme.phone}{

  display: none;
}
`;
const BannerComponent = Styled(Flex)`
width: 100%;
top: 0;
left: 0;
z-index: 5;
position: fixed;
top: 0;

.banner-text {
  text-transform: none;
  margin: ${baseTheme.space[2]}px;
  padding: 3px 4px 2px 4px;
     color: ${baseTheme.colors.darkPurple} !important;
}

.banner-text p {
  margin-bottom: 0;

}

a {
 text-decoration: underline;

}



`;

class Banner extends React.PureComponent {
  render() {
    return (
      <BannerComponent
        px={3}
        py={2}
        width={1}
        subdomain={process.env.SUBDOMAIN || process.env.REACT_APP_SUBDOMAIN ? 'provider' : ''}
        bg={process.env.SUBDOMAIN || process.env.REACT_APP_SUBDOMAIN ? 'lightPurple' : 'darkPurple'}
        alignItems="center"
        justifyContent="center"
      >
        <Flex width={[1, 1, 0.75, 0.75]} alignItems="center" justifyContent="center">
          <Flex alignItems="center">
            <BadgeCont>
              <Badge text={this.props.badgeText} />
            </BadgeCont>
            <BannerTextMarkdown options={Renderers()}>
              {this.props.mainText ? this.props.mainText : ''}
            </BannerTextMarkdown>
          </Flex>
        </Flex>
      </BannerComponent>
    );
  }
}
Banner.propTypes = {
  mainText: PropTypes.string,
  badgeText: PropTypes.string
};

Banner.defaultProps = {
  mainText: 'Main Text',
  badgeText: 'New'
};

/** @component */
export default Banner;
