import React from 'react';
import { Link } from 'react-router-dom';
import isExternal from 'is-url-external';

/**
 * Link that also works for external URL's
 */
export default class DynamicLink extends React.PureComponent {
  render() {
    let dynamicLinkForRender;

    if (this.props.href && this.props.href.includes('bitcoin:')) {
      dynamicLinkForRender = (
        <a href={this.props.href} target="_blank" rel="noopener noreferrer" className="bitcoin" {...this.props}>
          {this.props.children}
        </a>
      );
    } else if (this.props.href && this.props.href.includes('mailto:')) {
      dynamicLinkForRender = (
        <a href={this.props.href} className="external" {...this.props}>
          {this.props.children}
        </a>
      );
    } else {
      this.props.href &&
      (isExternal(this.props.href) ||
        this.props.href.includes('http') ||
        this.props.href.includes('www') ||
        this.props.href.includes('tidepool.org'))
        ? (dynamicLinkForRender = (
            <a href={this.props.href} className="external" {...this.props}>
              {this.props.children}
            </a>
          ))
        : this.props.dummy
          ? (dynamicLinkForRender = (
              <a className="dummy" href="null">
                {this.props.children}
              </a>
            ))
          : (dynamicLinkForRender = (
              <Link className="internal" {...this.props} to={this.props.href ? this.props.href : '/'}>
                {this.props.children}
              </Link>
            ));
    }
    return dynamicLinkForRender;
  }
}
