import React, { useEffect } from 'react';
import { Flex } from 'rebass';
import Styled from 'styled-components';

const DoubleTextBlockInner = Styled.div`
width: 100%;
height: auto;
position: relative;
height: 700px;


`;

const InnerSpace = Styled(Flex)`
  width: 100vw;
  position: relative;
  justify-content: center;
  left: calc(-50vw + 50%);

`;

const InnerInnerSpace = Styled.div`
  width: 100%;
  position: relative;
  max-width: 980px;
  margin: 0 auto;
  padding: 0 24px;

`;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    if (this.props.entryObject.fields.marketoId) {
      window.MktoForms2.loadForm('//app-sj30.marketo.com', '248-ISZ-536', this.props.entryObject.fields.marketoId);
    }
  }
  // make sure to remove the listener
  // when the component is not mounted anymore

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    return (
      <DoubleTextBlockInner>
        {this.props.entryObject.fields.marketoId ? (
          <form id={`mktoForm_${this.props.entryObject.fields.marketoId}`} />
        ) : (
          <InnerSpace>
            <InnerInnerSpace>
              <iframe
                title={this.props.entryObject}
                src={
                  this.props.entryObject.fields.iFrameForm +
                  (this.props.entryObject.fields.urlParameterAppend
                    ? this.props.entryObject.fields.urlParameterAppend
                    : '?autosize=true')
                }
                width={
                  this.state.width < 640
                    ? this.state.width - 30 + 'px'
                    : this.state.width > 1000
                    ? '980px'
                    : this.state.width * 0.8 + 'px'
                }
                height="700px"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              />
            </InnerInnerSpace>
          </InnerSpace>
        )}
      </DoubleTextBlockInner>
    );
  }
}

export default Form;
