import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { Flex } from 'rebass';
import Avatar from './Avatar';
import { TopNavLink, Body1, Caption } from '../FontStyles';
import baseTheme from '../../themes/baseTheme';

const SubduedText = Styled(Caption)`
color: ${baseTheme.colors.primaryTextSubdued};
`;

const TextForNewMenu = Styled(Body1)`
color: ${(props) => (props.linkStyle === 'activeNav' ? baseTheme.colors.mediumPurple : 'inherit')};
`;
const InnerNewMenuText = Styled(Flex)`

padding-left: ${(props) => (props.linkStyle === 'activeNav' ? baseTheme.space[2] : '0')}px;
border-left: ${(props) => (props.linkStyle === 'activeNav' ? `${baseTheme.colors.mediumPurple} 3px solid ` : '')};
`;

const NavListItemWrapper = Styled(Flex)`
width: 100%;
height: auto;
color: ${baseTheme.colors.primaryFont};
background-color: #fff;
padding: ${(props) => (!props.secondary ? '12' : baseTheme.space[3])}px;
flex-direction: row;
justify-content: flex-start;
align-items: center;
overflow: visible;
position: relative;




${Body1}, ${SubduedText}{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-align: left;
}



&:hover {
  color: ${baseTheme.colors.mediumPurple};
  border-color: ${baseTheme.colors.mediumPurple};
  box-shadow: none;
  background-color: ${baseTheme.colors.lightGrey}
  z-index: 1995;
}


&:focus {
  box-shadow: none;
}

&.active {
  color: #fff;
  border-color: ${baseTheme.colors.mediumPurple};
  background-color: ${baseTheme.colors.mediumPurple};
  box-shadow: none;
}

`;

const TopNavOuterWrapper = Styled(TopNavLink)`
position: relative;


`;

const Navatar = Styled(Avatar)`
margin-right: ${baseTheme.space[2]}px;
`;

class NavListItem extends React.PureComponent {
  render() {
    const { entry, includeAvatar } = this.props;

    const navItemForRender = this.props.external ? (
      <a href={this.props.to}>
        <NavListItemWrapper {...this.props} py={!this.props.secondary && '12'}>
          {includeAvatar && entry.fields.navItemAvatar ? (
            <Flex mr={3}>
              <Navatar
                avatarSize="small"
                avatarImageUrl={entry.fields.navItemAvatar.fields.file.url}
                alt={entry.fields.navItemAvatar.fields.description}
              />
            </Flex>
          ) : (
            <div />
          )}
          <Flex pl={this.props.pl ? this.props.pl : 0} flexWrap="wrap" width={1}>
            <Flex width={1}>
              <Flex width={1}>
                <Body1 width={1}>{entry.fields.navItemLinkText} </Body1>
              </Flex>
            </Flex>
            <Flex width={1}>
              <SubduedText css={{ marginBottom: '0' }} width={1}>
                {this.props.secondary && this.props.isNav && entry.fields.navItemSecondaryText}
              </SubduedText>
            </Flex>
          </Flex>
        </NavListItemWrapper>
      </a>
    ) : (
      <TopNavOuterWrapper activeClassName="activeNavLink" to={this.props.to}>
        <NavListItemWrapper {...this.props} py={!this.props.secondary && '12'} className="NavListItemWrapper">
          {includeAvatar && entry.fields.navItemAvatar && (
            <Flex mr={3}>
              <Navatar avatarSize="small" avatarImageUrl={entry.fields.navItemAvatar.fields.file.url} />
            </Flex>
          )}
          <Flex flexWrap="wrap" pl={this.props.pl ? this.props.pl : 0} width={1}>
            <Flex width={1}>
              <Body1 width={1}>{entry.fields.navItemLinkText} </Body1>
            </Flex>
            <Flex width={1}>
              <SubduedText css={{ marginBottom: '0' }} width={1}>
                {this.props.secondary && this.props.isNav && entry.fields.navItemSecondaryText}
              </SubduedText>
            </Flex>
          </Flex>
        </NavListItemWrapper>
      </TopNavOuterWrapper>
    );
    return navItemForRender;
  }
}

NavListItem.propTypes = {
  entry: PropTypes.object
};

NavListItem.defaultProps = {
  includeAvatar: true,
  external: false,
  entry: {
    fields: {
      quote: 'Quote Text Here',
      quotedPerson: 'Howard Look',
      quotedPersonTitle: 'Founder, Tidepool',
      navItemSecondaryText: '',
      pageLinked: {
        fields: {
          slug: ''
        }
      },
      avatarImage: {
        fields: {
          file: {
            url: ''
          }
        }
      }
    }
  }
};

/** @component */
export default NavListItem;
