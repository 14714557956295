import React from 'react';
import { Box } from 'rebass';
import Styled from 'styled-components';

const ImageCont = Styled(Box)`
text-align: center;
`;

const BlogImageActual = Styled.img`
max-width: 100%;
height: auto;


`;

// 128: xlarge, 96 large, 72 medium, 40 small.

class BlogImage extends React.PureComponent {
  render() {
    return (
      <ImageCont mb={4} width={1} justifyContent="flex-start">
        <BlogImageActual src={this.props.src} alt={this.props.alt} className="BlogImage" />
      </ImageCont>
    );
  }
}
BlogImage.propTypes = {};

BlogImage.defaultProps = {};

/** @component */
export default BlogImage;
