import React, { Component } from 'react';
import Styled from 'styled-components';

import { Flex, Box } from 'rebass';
import ProgressiveImage from './LazyProgImage';

const TheImageCont = Styled(Box)`

width: 100%;
height: 100%;
overflow: hidden;

`;

const TheImage = Styled.img`
max-height: 100%;
max-width: 100%;
width: ${props => (props.loading ? '' : 'auto')};
height: auto;

`;

// 128: xlarge, 96 large, 72 medium, 40 small.
class ProgImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 100
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {}

  render() {
    // console.log(this.props.img.id)
    return (
      <Flex ref={this.containerRef} id={this.props.alt} width={1}>
        <ProgressiveImage
          src={this.props.src}
          alt={this.props.alt}
          visibilitySensorProps={{ active: true, partialVisibility: true }}
          placeholder={this.props.placeholder ? this.props.placeholder : `${this.props.src}?w=25`}
        >
          {(src, loading) => (
            <TheImageCont loading={loading}>
              <TheImage loading={loading} width={this.props.width} src={src} alt={this.props.alt} />
            </TheImageCont>
          )}
        </ProgressiveImage>
      </Flex>
    );
  }
}
ProgImage.propTypes = {};

ProgImage.defaultProps = {};

/** @component */
export default ProgImage;
