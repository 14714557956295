import React from 'react';
import { Flex } from 'rebass';
import Styled from 'styled-components';
import ProgImage from './ProgImage';

const MkdwnImage = Styled(Flex)`
  max-width: 100%;

  & img {
  max-width: 100%;
  }
`;

// 128: xlarge, 96 large, 72 medium, 40 small.
class MarkdownImage extends React.PureComponent {
  render() {
    return (
      <MkdwnImage mb={2} alignItems="center" justifyContent="center">
        <img alt={this.props.alt} src={this.props.src} />
      </MkdwnImage>
    );
  }
}

/** @component */
export default MarkdownImage;
