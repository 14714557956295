import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createClient } from 'contentful';
import Loadable from 'react-loadable';
import Header from './components/Header';
import ScrollToTop from './components/elements/ScrollToTop';
import baseTheme from './themes/baseTheme';
import './custom-style.css';
import { loader } from './functions';
import logo from './images/logo.svg';

const LoadableRoute = Loadable({
    loader: () => import('./components/PageContainer'),
    loading: loader
});

const BlogPost = Loadable({
    loader: () => import('./components/patterns/BlogPost'),
    loading: loader
});

const BlogList = Loadable({
    loader: () => import('./components/patterns/BlogList'),
    loading: loader
});

const FourOhFour = Loadable({
    loader: () => import('./components/patterns/FourOhFour'),
    loading: loader
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: [],
            ready: false,
            showBanner: false,
            jobPages: [],
            blogPosts: [],
            blogReady: false,
            redirects: [],
            bannerComponent: [],
            mainBlog: {}
        };
    }

    componentDidMount() {
        let client;
        let subdomainClient;

        // if there's a subdomain we're going to use that for the content outside of the banner, header and footer (static partrs of the experience)
        if (process.env.REACT_APP_SUBDOMAIN) {
            subdomainClient = createClient({
                space: process.env.SUBDOMAIN_SPACE
                    ? process.env.SUBDOMAIN_SPACE
                    : process.env.REACT_APP_SUBDOMAIN_SPACE,
                accessToken: process.env.SUBDOMAIN_ACCESS_TOKEN
                    ? process.env.SUBDOMAIN_ACCESS_TOKEN
                    : process.env.REACT_APP_SUBDOMAIN_ACCESS_TOKEN,
                host: process.env.REACT_APP_CONTENTFUL_HOST ? process.env.REACT_APP_CONTENTFUL_HOST : ''
            });

            subdomainClient.getEntries({ content_type: 'pageContainer', include: 10, limit: 1000 }).then((response) => {
                this.setState({ pages: response, ready: true });
            });
        } else {
            client = createClient({
                space: process.env.SPACE ? process.env.SPACE : process.env.REACT_APP_SPACE,
                accessToken: process.env.ACCESS_TOKEN ? process.env.ACCESS_TOKEN : process.env.REACT_APP_ACCESS_TOKEN,
                host: process.env.REACT_APP_CONTENTFUL_HOST ? process.env.REACT_APP_CONTENTFUL_HOST : ''
            });
            client.getEntries({ content_type: 'pageContainer', include: 10, limit: 1000 }).then((response) => {
                this.setState({ pages: response, ready: true });
            });
            client.getEntries({ content_type: 'jobPostPage', include: 10, limit: 1000 }).then((response) => {
                this.setState({ jobPages: response });
            });
        }

        const blogSpaceClient = createClient({
            accessToken: process.env.REACT_APP_BLOG_ACCESS_TOKEN,
            space: process.env.REACT_APP_BLOG_SPACE,
            host: process.env.REACT_APP_CONTENTFUL_HOST ? process.env.REACT_APP_CONTENTFUL_HOST : ''
        });

        // Get the page container component from CMS.

        blogSpaceClient.getEntries({ content_type: 'redirects', include: 10, limit: 1000 }).then((response) => {
            this.setState({ redirects: response.items[0].fields.activeRedirects });
        });
        blogSpaceClient.getEntries({ content_type: 'blogPost', include: 10, limit: 1000 }).then((response) => {
            this.setState({
                blogPosts: {
                    ...response,
                    items: response.items.filter(item => !!item.fields.postTitle && !!item.fields.postContent),
                },
                blogReady: true
            });
        });

        // Get the page container component from CMS.
        if (process.env.REACT_APP_SUBDOMAIN === 'provider') {
            createClient({
                space: process.env.SUBDOMAIN_SPACE
                    ? process.env.SUBDOMAIN_SPACE
                    : process.env.REACT_APP_SUBDOMAIN_SPACE,
                accessToken: process.env.SUBDOMAIN_ACCESS_TOKEN
                    ? process.env.SUBDOMAIN_ACCESS_TOKEN
                    : process.env.REACT_APP_SUBDOMAIN_ACCESS_TOKEN,
                host: process.env.REACT_APP_CONTENTFUL_HOST ? process.env.REACT_APP_CONTENTFUL_HOST : ''
            });
            subdomainClient.getEntries({ content_type: '7lxO3Xf7w3eYEPmsG0cBsW', limit: 1000 }).then((response) => {
                this.setState({ showBanner: response.items[0].fields.enabled, bannerComponent: response });
            });
        } else {
            client.getEntries({ 'sys.id': '73DP9oYcpOimuWcQCaociA', limit: 1000 }).then((response) => {
                this.setState({ showBanner: response.items[0].fields.enabled, bannerComponent: response });
            });
        }
    }

    render() {
        let routesToRender;
        let blogRoutesToRender;
        let jobRoutesToRender;
        let categoryRoutesToRender;
        let redirectsToRender;
        let blogRedirectsToRender;

        if (this.state.ready) {
            redirectsToRender = this.state.redirects.map((entry) => {
                if (entry.fields.fromSlug && (
                    entry.fields.redirectToSlug.indexOf('http://') === 0 ||
                    entry.fields.redirectToSlug.indexOf('https://') === 0
                )) {
                    return (
                        <Route
                            key={entry.fields.redirectToSlug}
                            path={entry.fields.fromSlug}
                            component={() => {
                                setTimeout(() => {
                                    window.location = entry.fields.redirectToSlug;
                                }, 400);

                                return null;
                            }}
                        />
                    );
                }

                return entry.fields.fromSlug && entry.fields.redirectToSlug ? (
                    <Route
                        exact
                        key={entry.fields.fromSlug}
                        path={entry.fields.fromSlug}
                        render={() => <Redirect to={entry.fields.redirectToSlug} />}
                    />
                ) : null;
            });
            routesToRender = this.state.pages.items.map((entry) => entry.fields.slug ? (
                <Route
                    exact
                    key={entry.fields.slug}
                    path={entry.fields.slug}
                    component={() => (
                        <LoadableRoute showBanner={this.state.showBanner} pageId={entry.sys.id} entryObject={entry} />
                    )}
                />
            ) : null);
            if (this.state.jobPages.items) {
                jobRoutesToRender = this.state.jobPages.items.map((entry) => entry.fields.slug ? (
                    <Route
                        exact
                        key={entry.fields.slug}
                        path={entry.fields.slug}
                        component={() => (
                            <LoadableRoute
                                showBanner={this.state.showBanner}
                                pageId={entry.sys.id}
                                entryObject={entry}
                            />
                        )}
                    />
                ) : null);
            }
            if (this.state.blogPosts.items) {
                blogRoutesToRender = this.state.blogPosts.items.map((entry) => entry.fields.slug ? (
                    <Route
                        exact
                        key={entry.fields.slug}
                        path={`/blog/${entry.fields.slug}`}
                        component={() => (
                            <BlogPost currentSlug={`/blog/${entry.fields.slug}`} entryObject={entry} />
                        )}
                    />
                ) : null);
                blogRedirectsToRender = this.state.blogPosts.items.map((entry) => entry.fields.slug ? (
                    <Route
                        exact
                        key={entry.fields.slug}
                        path={`/${entry.fields.slug}`}
                        render={() => <Redirect to={`/blog/${entry.fields.slug}`} />}
                    />
                ) : null);
                let categoryNames = [];
                let finalCategories;
                categoryRoutesToRender = this.state.blogPosts.items.map((entry) => {
                    entry.fields.postCategories
                        ? (finalCategories = entry.fields.postCategories.map((category) =>
                              categoryNames.indexOf(category) === -1 ? categoryNames.push(category) : null
                          ))
                        : (finalCategories = '');

                    return categoryNames.map((category) => (
                        <Route
                            finalCategories={finalCategories}
                            exact
                            key={entry.fields.slug}
                            path={`/blog/categories/${category.replace(/ +/g, '-').toLowerCase()}`}
                            component={() => (
                                <BlogList category={category} allCategories={categoryNames} entryObject={entry} />
                            )}
                        />
                    ));
                });

                categoryRoutesToRender.push(
                    <Route
                        exact
                        key="primary blog"
                        path="/blog"
                        component={() => (
                            <BlogList
                                allCategories={categoryNames}
                                blogBlocks={
                                    this.state.mainBlog.includes ? this.state.mainBlog.includes.Entry : undefined
                                }
                                seoTitle="Tidepool Blog"
                            />
                        )}
                    />
                );
            }
        }
        return (
            <BrowserRouter>
                <ScrollToTop>
                    <ThemeProvider theme={baseTheme}>
                        <div>
                            <Helmet>
                                Tidepool
                                <meta
                                    name="description"
                                    content="Tidepool is a nonprofit organization dedicated to making diabetes data more accessible, actionable, and meaningful for people with diabetes, their care teams, and researchers."
                                />
                                <meta name="twitter:card" content="summary" />
                                <meta
                                    name="twitter:description"
                                    content="Tidepool is a nonprofit organization dedicated to making diabetes data more accessible, actionable, and meaningful for people with diabetes, their care teams, and researchers. "
                                />
                                <meta name="twitter:image" content={logo} />
                            </Helmet>
                            <Header showBanner={this.state.showBanner} bannerComponent={this.state.bannerComponent} />
                            <Switch>
                                {console.log(redirectsToRender)}
                                {redirectsToRender}
                                {routesToRender}
                                {jobRoutesToRender}
                                {blogRoutesToRender}
                                {categoryRoutesToRender}
                                {blogRedirectsToRender}
                                {this.state.ready && this.state.blogReady && <Route key="404" component={FourOhFour} />}
                            </Switch>
                        </div>
                    </ThemeProvider>
                </ScrollToTop>
            </BrowserRouter>
        );
    }
}

export default App;
