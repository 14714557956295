export default {
  colors: {
    purple: {
      lightPurple: '#DCE0F9',
      mediumPurple: '#6582FF',
      darkPurple: '#271B46'
    },
    blues: [
      '#F0F9FD',
      '#BFE8F9',
      '#7ED1F2',
      '#5DBEE6',
      '#28A7DA',
      '#0096D1',
      '#008ECC',
      '#0083C6',
      '#0079C0',
      '#0068B5',
      '#DEEFFF',
      '#ABD6FF',
      '#78BDFF',
      '#5EB1FF'
    ],
    cyans: [
      '#DFFAFB',
      '#B2F2F7',
      '#7FE9F3',
      '#4DE0EE',
      '#25D9EA',
      '#00D3E6',
      '#00CADD',
      '#00BED0',
      '#00B2C3',
      '#009EAC',
      '#99FFFF',
      '#66FCFF',
      '#33F1FF',
      '#00E9FA'
    ],
    grays: [
      '#EDEDED',
      '#D3D3D3',
      '#B6B6B6',
      '#999999',
      '#838383',
      '#6D6D6D',
      '#656565',
      '#5A5A5A',
      '#505050',
      '#3E3E3E'
    ],
    greens: [
      '#E6F8ED',
      '#C3EED4',
      '#9DE4B8',
      '#76DB9B',
      '#58D386',
      '#3BCC71',
      '#35C269',
      '#2CB85D',
      '#24AE53',
      '#159B41',
      '#96FAB1',
      '#63F7A8',
      '#2CE993',
      '#43E383'
    ],
    indigos: [
      '#DCDFF9',
      '#C8CDFA',
      '#B1BEFF',
      '#91A3FF',
      '#7990FF',
      '#627CFF',
      '#536BEE',
      '#4457D9',
      '#3446BE',
      '#1F2B98',
      '#8C9EFF',
      '#627CFF',
      '#3D5AFE',
      '#304FFE'
    ],
    oranges: [
      '#FFF7EF',
      '#FFCF9F',
      '#FFB686',
      '#FF8C5C',
      '#FF5F37',
      '#F4511E',
      '#E64A19',
      '#D84315',
      '#BF360C',
      '#FF9E80',
      '#FF6E40',
      '#FF3D00',
      '#DD2C00'
    ],
    pinks: [
      '#FFECEE',
      '#FFD0D2',
      '#F7A9A7',
      '#F28684',
      '#F76C68',
      '#FB5951',
      '#F2504B',
      '#E94542',
      '#E33D3A',
      '#DB2E2C',
      '#FFC5C0',
      '#FFA9A9',
      '#FF7F96',
      '#EA6867'
    ],
    purples: [
      '#EDE9F6',
      '#D9CEED',
      '#B49DE3',
      '#A17EDE',
      '#8C65D6',
      '#744AC2',
      '#5C3AA6',
      '#472A87',
      '#321C66',
      '#281946',
      '#D9C3FF',
      '#BDA6FF',
      '#A280FF',
      '#9365F4'
    ]
  }
};
