import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { createClient } from 'contentful';
import Styled from 'styled-components';
import Loadable from 'react-loadable';
import { Image, Box, Flex } from 'rebass';
import { Subheading, Display1 } from './FontStyles';
import NavListItem from './elements/NavListItem';
import baseTheme from '../themes/baseTheme';
import Banner from './elements/Banner';
import logo from '../images/logo.svg';
import Button from './elements/Button';
import SubdomainNav from './patterns/SubdomainNav';
import NavigationDropdown from './elements/NavigationDropdown';
import { loader } from '../functions';

const Accordion = Loadable({
  loader: () => import('./elements/Accordion'),
  loading: () => loader
});

const Modal = Loadable({
  loader: () => import('./elements/Modal'),
  loading: () => loader
});

const FullWidthHeader = Styled(Flex)`
width: 100%;
heigth: auto;
flex-wrap: wrap;
position: fixed;
top: ${(props) => (props.showBanner ? '104px' : '56px')};
z-index: 11;
background-color: #fff;
border-bottom: ${baseTheme.border};

@media (max-width: 1150px){
  top: ${(props) => (props.showBanner ? '48px' : '0px')};
}

@media (max-width: 520px){
  top: ${(props) => (props.showBanner ? '30px' : '0px')};
}
`;

const CloseButton = Styled(Box)`

cursor: pointer;
float: right;
text-align: right;

& ${Display1} {
  line-height: 30px;
}

`;

const LinkList = Styled.ul`
list-style: none;
padding-left: 0;
width: 100%;
display: flex;
flex-wrap: wrap;
margin-top: 0;
margin-bottom: 16px;
`;

const NavListItemLi = Styled.li`
width: ${(props) => props.width};
margin-left: 0;
padding-left: 0;

& .NavListItemWrapper {
padding-left: 0;
}

`;

const ResponsiveLogoCont = Styled(Flex)`
height: 56px;


`;

const HeaderWrapper = Styled(Flex)`
max-width: ${baseTheme.gridSize}px;
padding: 0px ${baseTheme.layoutSpacingSmall}px;
width: 100%;
align-items: space-between;
justify-items: middle;
flex-wrap: wrap;
align-self: center;
justify-self: center;
margin: 0 auto;
height: 64px;


.right-nav {
  justify-content: flex-end;
  z-index: 20;
  min-width: 300px;
}
`;

const DropdownCont = Styled(Flex)`
height: 100%;
align-items: flex-end;

`;

const MainNavDropdown = Styled(NavigationDropdown)`

`;

const ImageBox = Styled(Box)`
`;

const LogoCont = Styled(Flex)`

@media (max-width: 785px){
  justify-content: flex-end;
}
`;

const DrawerNavSubheading = Styled(Subheading)`
margin-top: 0;
margin-bottom: 4px;
color: ${baseTheme.colors.mediumPurple};
`;

const BurgerNavButtonCont = Styled(Flex)`

display: none;

@media (max-width: 900px){
  display: flex;
}
`;

const MenuIcon = Styled(Box)`

& i {
  color: ${baseTheme.colors.primaryFont};
  font-size: 30px;
  cursor: pointer;
}
`;

const HeaderButtonGroup = Styled(Flex)`
position: relative;
z-index: 9999;
height: 100%;
.small {
cursor: pointer;
}

.lastButton {
  &.textUnderlined {
    padding-right: 0px;
  }
  margin-right: 0px;
}
@media (max-width: 900px){
  display: none;
}

`;

const ButtonContainer = Styled(Flex)`

@media (max-width: 900px){
  display: none;
}


`;

const ResponsiveModalButtonContainer = Styled(Flex)`

`;

const ResponsiveNavModal = Styled(Modal)`
display: none;
z-index: 99999999999999999;
@media (max-width: 900px){
  display: block;
}
`;

const NavLinkGroups = Styled(Flex)`
height: 100%;

.lastButton {
  &.textUnderlined {
    padding-right: 0px;
  }
  margin-right: 0px;
}



`;

const modalMenuStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0 auto',
    padding: '0',
    width: '100%',
    minHeight: '100%',
    marginRight: '-50%',
    transform: 'translate(-50%)'
  }
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerComponent: [],
      showBanner: false,
      bannerComponent: this.props.bannerComponent,
      ready: false,
      isOpen: false,
      active: 'false'
    };
    this.findActiveDropdown = this.findActiveDropdown.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    let client;
    if (process.env.SUBDOMAIN_SPACE || process.env.REACT_APP_SUBDOMAIN_SPACE) {
      console.log('header');
      client = createClient({
        space: process.env.SUBDOMAIN_SPACE ? process.env.SUBDOMAIN_SPACE : process.env.REACT_APP_SUBDOMAIN_SPACE,
        accessToken: process.env.SUBDOMAIN_ACCESS_TOKEN
          ? process.env.SUBDOMAIN_ACCESS_TOKEN
          : process.env.REACT_APP_SUBDOMAIN_ACCESS_TOKEN,
        host: process.env.REACT_APP_CONTENTFUL_HOST ? process.env.REACT_APP_CONTENTFUL_HOST : ''
      });
    } else {
      client = createClient({
        space: process.env.SPACE ? process.env.SPACE : process.env.REACT_APP_SPACE,
        accessToken: process.env.ACCESS_TOKEN ? process.env.ACCESS_TOKEN : process.env.REACT_APP_ACCESS_TOKEN,
        host: process.env.REACT_APP_CONTENTFUL_HOST ? process.env.REACT_APP_CONTENTFUL_HOST : ''
      });
    }

    ReactGA.initialize(process.env.REACT_APP_GAID);

    client.getEntries({ content_type: 'header', include: 4, limit: 1000 }).then((response) => {
      this.setState({ headerComponent: response, ready: true });
    });

    // Get the page container component from CMS.
    client.getEntries({ content_type: 'banner', limit: 1000 }).then((response) => {
      this.setState({ bannerComponent: response, showBanner: response.items[0].fields.enabled });
    });
  }

  toggleMenu = () => {
    this.state.isOpen ? this.setState({ isOpen: false }) : this.setState({ isOpen: true });
  };

  findActiveDropdown = (dropdownsForRender) => {
    let isActive;
    dropdownsForRender.map((dropdownGroup) => {
      dropdownGroup.fields.subnavItems.map((item) => {
        if (item.sys.contentType) {
          if (item.fields.pageLinked) {
            if (item.fields.pageLinked.fields.slug === window.location.pathname) {
              isActive = dropdownGroup.fields.groupName;
            }
          }
        }
        return item;
      });
      return dropdownGroup;
    });

    return isActive;
  };

  render() {
    let banner;
    let dropdownsForRender;

    let responsiveNavComponentsForRender;
    let theLink;
    let dropdownItems;
    const { headerComponent, bannerComponent } = this.state;

    if (this.state.showBanner) {
      banner = (
        <Banner
          badgeText={bannerComponent.items['0'].fields.badgeText}
          mainText={bannerComponent.items['0'].fields.bannerText}
        />
      );
    }
    if (this.state.ready) {
      dropdownsForRender = headerComponent.items['0'].fields.subnavDropdownLists.map((dropdown, i) => {
        let componentForRender;

        if (dropdown.sys.contentType.sys.id === 'subnavDropdown') {
          componentForRender = (
            // <MainNavDropdown key={dropdown.sys.id} activeMenu={activeMenu} headerComponent={dropdown} />
            <MainNavDropdown key={dropdown.sys.id} headerComponent={dropdown} />
          );
        }

        return componentForRender;
      });

      if (this.state.accordion) {
        responsiveNavComponentsForRender = headerComponent.items['0'].fields.subnavDropdownLists.map((dropdown) => (
          <Accordion
            isNav
            navGroupTitle={dropdown.fields.groupName}
            navItemText="Placeholder Text"
            dropdownGroup={dropdown}
            key={dropdown.fields.groupName}
          />
        ));
      }

      responsiveNavComponentsForRender = headerComponent.items['0'].fields.subnavDropdownLists.map((dropdown, i) => {
        const index = i;
        return (
          <Flex flexWrap="wrap" className="yo" width={1}>
            <Flex pl={3} width={1}>
              <DrawerNavSubheading isOpen={this.state.isOpen} color="mediumPurple">
                {dropdown.fields.groupName}
              </DrawerNavSubheading>
            </Flex>
            <LinkList>
              {
                (dropdownItems = dropdown.fields.subnavItems.map((item, i) => {
                  if (item.sys.contentType) {
                    if (item.fields.pageLinked) {
                      theLink = item.fields.pageLinked.fields ? item.fields.pageLinked.fields.slug : '/';
                      return (
                        <NavListItemLi key={item.sys.id} width={index === 0 ? '100%' : '50%'}>
                          <NavListItem
                            to={theLink}
                            includeAvatar="false"
                            onClick={this.toggleMenu}
                            key={item.sys.id}
                            entry={item}
                            width={0.5}
                            secondary={index === 0}
                            isNav
                            pl={16}
                          >
                            {item.fields.navItemLinkText}
                          </NavListItem>
                        </NavListItemLi>
                      );
                    } else {
                      theLink = item.fields.externalLink ? item.fields.externalLink : '/';

                      return (
                        <NavListItemLi key={item.sys.id} width={index === 0 ? '100%' : '50%'}>
                          <NavListItem
                            external
                            to={theLink}
                            onClick={this.toggleMenu}
                            includeAvatar="false"
                            key={item.sys.id}
                            entry={item}
                            width={0.5}
                            isNav
                            pl={16}
                          >
                            <Flex>{item.fields.navItemLinkText}</Flex>
                          </NavListItem>
                        </NavListItemLi>
                      );
                    }
                  }
                  return dropdownItems;
                }))
              }
            </LinkList>
          </Flex>
        );
      });
    }

    return (
      <FullWidthHeader showBanner={this.state.showBanner}>
        {banner}
        <SubdomainNav showBanner={this.state.showBanner} />
        <HeaderWrapper>
          <Flex width={1} className="logo" alignItems="center" justifyContent="space-between">
            <LogoCont alignItems="center" m={[0]} p={0}>
              <Link to="/">
                <ImageBox width={[1, 200]}>
                  <Image src={logo} />
                </ImageBox>
              </Link>
            </LogoCont>

            <BurgerNavButtonCont width={0.5} justifyContent="flex-end" color="palevioletred">
              <MenuIcon>
                <i onClick={this.toggleMenu} className="material-icons">
                  menu
                </i>
              </MenuIcon>

              <ResponsiveNavModal isOpen={this.state.isOpen} style={modalMenuStyles}>
                <Flex flexWrap="wrap" width={1}>
                  <ResponsiveLogoCont width={1} justifyContent="space-between" pl={3} alignItems="center">
                    <Link to="/">
                      <ImageBox width={[175]}>
                        <Image src={logo} />
                      </ImageBox>
                    </Link>
                    <CloseButton pr={2} justifySelf="flex-end" justifyContent="flex-end">
                      <Display1>
                        <i onClick={this.toggleMenu} class="material-icons">
                          close
                        </i>
                      </Display1>
                    </CloseButton>
                  </ResponsiveLogoCont>
                  <ResponsiveModalButtonContainer width={1} mt={2} mb={3} px={3} className="Buttons">
                    <Button
                      width={0.5}
                      mr={2}
                      to="https://app.tidepool.org/signup"
                      onClick={() => ReactGA.event({ category: 'click', action: 'Sign up patient', label: window.location.pathname })}
                      className="small outline"
                      text="Personal Sign Up"
                    />
                    <Button
                      width={0.5}
                      mr={2}
                      to={process.env.REACT_APP_FORMSORT_CLINICIAN_SIGNUP_URL}
                      onClick={() => ReactGA.event({ category: 'click', action: 'Sign up clinician', label: window.location.pathname })}
                      className="small"
                      text="Clinician Sign Up"
                    />
                    <Button
                      to="https://app.tidepool.org/login"
                      onClick={() => ReactGA.event({ category: 'click', action: 'Log in', label: window.location.pathname })}
                      className="small textUnderlined lastButton"
                      text="Log In"
                    />
                  </ResponsiveModalButtonContainer>
                  {responsiveNavComponentsForRender}
                  <LinkList>
                    <NavListItemLi width="100%">
                      <NavListItem
                        external
                        to="tidepool.org"
                        onClick={this.toggleMenu}
                        includeAvatar="false"
                        width={0.5}
                      >
                        <Flex> Tidepool.org</Flex>
                      </NavListItem>
                    </NavListItemLi>
                    <NavListItemLi width="100%">
                      <NavListItem
                        external
                        to="tidepool.org"
                        onClick={this.toggleMenu}
                        includeAvatar="false"
                        width={0.5}
                        className="activeNav"
                      >
                        <Flex>Healthcare Providers</Flex>
                      </NavListItem>
                    </NavListItemLi>
                    <NavListItemLi width="100%">
                      <NavListItem
                        external
                        to="tidepool.org"
                        onClick={this.toggleMenu}
                        includeAvatar="false"
                        width={0.5}
                      >
                        <Flex>Support</Flex>
                      </NavListItem>
                    </NavListItemLi>
                  </LinkList>
                </Flex>
              </ResponsiveNavModal>
            </BurgerNavButtonCont>

            <HeaderButtonGroup
              width={0.75}
              className="right-nav"
              mx={0}
              px={0}
              justifyContent="flex-end"
              alignItems="center"
            >
              <NavLinkGroups
                display={['none', 'none', 'block', 'block']}
                width={0.5}
                mx={0}
                px={0}
                justifyContent="flex-end"
                alignItems="center"
              >
                <DropdownCont justifyContent="flex-end" flexDirection="row" alignItems="center">
                  {dropdownsForRender}
                </DropdownCont>
              </NavLinkGroups>
            </HeaderButtonGroup>
          </Flex>
        </HeaderWrapper>
      </FullWidthHeader>
    );
  }
}

export default Header;
