import React, { useEffect } from 'react';
import { Flex } from 'rebass';
import componentMap from './components/componentMap';
import Video from './components/elements/Video';
import BlogIframe from './components/patterns/BlogIframe';
import ProgImage from './components/elements/ProgImage';
import { Body2, Display1, Title, Headline, TextLink, Subheading, BlockQuote } from './components/FontStyles';
import DynamicLink from './components/elements/DynamicLink';
import BlogImage from './components/elements/BlogImage';

export const getContainedComponents = (mainBlocks) => {
  const compsToRender = [];
  // Generate the list of top level container components by  checking the content types that are attached to the page object in CMS then mapping those content type IDs to new array
  const blockLevelComponents = mainBlocks;

  // Get the second level components inside container components that are inside each of the container components
  blockLevelComponents &&
    blockLevelComponents.map((entry, i) => {
      // Format Component name for render

      if (typeof entry.sys.contentType !== 'undefined') {
        const formattedComponentName =
          entry.sys.contentType.sys.id.charAt(0).toUpperCase() + entry.sys.contentType.sys.id.slice(1);

        // Create hybrid contained component object for render
        const fullDataComponent = {
          entryId: entry.sys.id,
          entryObject: entry,
          entryName: formattedComponentName
        };

        // Push component object data to array for render
        compsToRender[i] = fullDataComponent;

        // Push component object data to array for render
      }
      return compsToRender;
    });
  return compsToRender;
};

export const renderComponents = (allComponents, loading, paddingY) => {
  if (allComponents) {
    const jsxComponents = allComponents.map((entry) => {
      const TagName = componentMap[entry.entryName];

      return (
        <TagName
          width={1}
          key={entry.entryId}
          theme={entry.theme}
          entryObject={entry.entryObject}
          loading={loading}
          paddingY={paddingY}
        />
      );
    });
    return jsxComponents;
  }
};

export const renderBlogEmbeddedComponents = (blogComponent, loading, paddingY) => {
  if (blogComponent) {
    const entryName =
      blogComponent.data.target.sys.contentType.sys.id.charAt(0).toUpperCase() +
      blogComponent.data.target.sys.contentType.sys.id.slice(1);
    // console.log('entryName');
    // console.log(entryName);
    const TagName = componentMap[entryName];

    return <TagName width={1} entryObject={blogComponent.data.target} loading={loading} paddingY={paddingY} />;
  }
};

export const initBeacon = () => {};

const COMPONENT_RENDER_MAP = {
  text: ({ marks, value }) => {
    return marks.length ? <span className={marks.map((mark) => mark.type).join(' ')}>{value}</span> : value;
  },
  paragraph: (item) => (
    <Flex width={1}>
      <Body2 className="para" style={{ width: '100%' }}>
        {item.content.map((item) => renderItem(item))}
      </Body2>
    </Flex>
  ),

  'heading-1': (item) => (
    <Flex my={4} width={1}>
      <Display1 as="h2">{item.content.map((item) => renderItem(item))}</Display1>
    </Flex>
  ),
  'heading-2': (item) => (
    <Flex my={3} width={1}>
      <Headline as="h3">{item.content.map((item) => renderItem(item))}</Headline>
    </Flex>
  ),
  'heading-3': (item) => (
    <Flex style={{ whiteSpace: 'pre-wrap' }} my={3} width={1}>
      <Title as="h3">{item.content.map((item) => renderItem(item))}</Title>
    </Flex>
  ),
  'heading-4': (item) => (
    <Flex my={4} width={1}>
      <Subheading as="h4">{item.content.map((item) => renderItem(item))}</Subheading>
    </Flex>
  ),
  'heading-5': (item) => (
    <Flex my={4} width={1} style={{ whiteSpace: 'pre-wrap' }}>
      <Subheading as="h5">{item.content.map((item) => renderItem(item))}</Subheading>
    </Flex>
  ),
  'heading-6': (item) => (
    <Flex my={3} width={1}>
      <Subheading as="h6">{item.content.map((item) => renderItem(item))}</Subheading>
    </Flex>
  ),
  blockquote: (item) => <BlockQuote>{item.content.map((item) => renderItem(item))}</BlockQuote>,
  'unordered-list': (item) => (
    <Flex width={1}>
      <ul style={{ marginBottom: '0px', width: '100%' }}>{item.content.map((item) => renderItem(item))}</ul>
    </Flex>
  ),
  'ordered-list': (item) => (
    <Flex width={1}>
      <ol style={{ marginBottom: '0px', width: '100%' }}>{item.content.map((item) => renderItem(item))}</ol>
    </Flex>
  ),
  'list-item': (item) => (
    <Flex width={1}>
      <Body2 style={{ marginBlockEnd: '0.5em' }} as="li">
        {item.content.map((item) => renderItem(item))}
      </Body2>
    </Flex>
  ),
  hr: (item) => (
    <Flex width={1}>
      <hr style={{ width: '100%' }} />
    </Flex>
  ),
  hyperlink: (item) => <DynamicLink href={item.data.uri}>{item.content.map((item) => renderItem(item))}</DynamicLink>,

  // embedded entries
  'asset-hyperlink': (item) => (
    <a href={item.data.target.fields.file.url} rel=" target=_blank noopener noreferrer">
      {item.content.map((item) => renderItem(item))}
    </a>
  ),
  'embedded-asset-block': (item) => (
    <BlogImage src={item.data.target.fields.file.url} alt={item.data.target.fields.description} />
  ),
  'embedded-entry-block': (item) => {
    const entry = item.data.target;
    //  console.log(entry);

    return <BlogIframe width={item.data} key={item.data.target} entryObject={item.data.target} />;
  },
  'embedded-entry-inline': (item) => <Flex width={1}>{renderBlogEmbeddedComponents(item)}</Flex>
};

// const logItem = item => {
//   console.log('\n\n--Rendering item---------------');
//   console.log(
//     `%c item.nodeType %c ${item.nodeType}`,
//     'background: #0074D9; color: #fff; border-radius: 3px',
//     'background: transparent; color: #444;'
//   );
//   console.log('%c item.content  ', 'background: #7FDBFF; color: #4a4a4a; border-radius: 3px', item.content);
//   console.log('%c item.value  ', 'background: #FFDC00; color: #4a4a4a; border-radius: 3px', item.value);
//   console.log('%c item.marks  ', 'background: #FF851B; color: #4a4a4a; border-radius: 3px', item.marks);
//   console.log('%c item (complete object)  ', 'background: #FF4136; color: #fff; border-radius: 3px', item);
// };

export const renderItem = (item) => {
  var renderedFunction = COMPONENT_RENDER_MAP[item.nodeType]
    ? COMPONENT_RENDER_MAP[item.nodeType](item)
    : COMPONENT_RENDER_MAP['embedded-entry-block'](item);

  return renderedFunction;
};

// Render embedded entries

export const loader = (props) => {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <div>Loading...</div>;
  } else {
    return <div>Loading...</div>;
  }
};

export const useEffectOnlyOnce = (func) => useEffect(func, []);
