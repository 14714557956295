import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import Styled from 'styled-components';
import { Flex } from 'rebass';
import baseTheme from '../../themes/baseTheme';

import { TopNavLinkExternal } from '../FontStyles';

import { TopNavLink } from '../FontStyles';

import Button from '../elements/Button';

const SubnavFullWidth = Styled(Flex)`
width: 100%;
height: auto;
flex-wrap: wrap;
position: fixed;
top: ${(props) => (props.showBanner ? '48px' : '0px')} ;
height: 56px;

border-top:  ${
  process.env.SUBDOMAIN === 'provider' || process.env.REACT_APP_SUBDOMAIN === 'provider'
    ? `8px solid ${baseTheme.colors.lightPurple}`
    : `8px solid ${baseTheme.colors.darkPurple}`
};


background-color: ${baseTheme.colors.lightestGrey};

margin: 0 auto;
transition: all .2s ease-out,transform .2s ease-out;

z-index: 2;


&.overlaid {
  position: fixed;
  margin-top: 0;
  top: ${(props) => (props.showBanner ? '48px' : '0')}
  z-index: 10;
  background-color: #fff;
  width: 100%;

}

@media (max-width: 1140px){
  display: none;
}



`;

const SubnavWrapper = Styled(Flex)`
justify-items: center;
top: ${(props) => (props.showBanner ? '63px' : '0px')} ;

height: 48px;
z-index: 10;
justify-content: space-between;
width: 100%;
max-width: auto;
margin: 0 auto;
border-bottom: ${baseTheme.border};

background-color: ${baseTheme.colors.lightestGrey};

max-width: 1200px;

@media (max-width: 1140px){
  display: none;

}

@media ${baseTheme.phone} {


}


`;

const TopNavigationLink = Styled(TopNavLinkExternal)`

padding: 0;
cursor: pointer;
margin-top: 2px;
align-self: center;
border-bottom: none;
height: 48px;
display: flex;
align-items: center;
white-space: nowrap;
background-color: transparent;

&:hover{
  height: 100%;
  align-self: center;
  color: ${baseTheme.colors.linkFont};
    border-bottom: ${baseTheme.borderNav};
}
`;

const SubNav1 = Styled(Flex)`

width: 100%;
background-color: ${baseTheme.colors.darkPurple};
border-radius: 4px;
position: absolute;
left: 0;
top: -200px;
overflow: hidden;
opacity: 0;
height: auto;
transition: all  0.15s ease-in-out 0.2s, top 0.01s ease-in-out 0.25s;
text-align: center;

&:hover {
  height: auto;
  opacity: 1;
  z-index: 2;
  top: 66px;
}
`;

const DropdownGroup1 = Styled(Flex)`
position: relative;
height: 100%;
text-align: center;

&:hover ${SubNav1} {
  transition: opacity 0.15s ease-in-out ;
  height: auto;
  opacity: 1;
  z-index: 2;
  top: 66px;

}


.activeNavLink {

  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: ${baseTheme.borderNav};
}
`;

const Central = Styled(Flex)`
padding-left: 4px;
background-color: ${baseTheme.colors.lightestGrey};
`;

const HeaderButtonGroup = Styled(Flex)`
position: relative;
z-index: 9999;
height: 100%;
.small {
cursor: pointer;
}

.lastButton {
  &.textUnderlined {
    padding-right: 0px;
  }
  margin-right: 0px;
}
@media (max-width: 900px){
  display: none;
}

`;

const ButtonContainer = Styled(Flex)`
padding-right: 30px;
@media (max-width: 900px){
  display: none;
}


`;

const NavLinkGroups = Styled(Flex)`
height: 100%;

.lastButton {
  &.textUnderlined {
    padding-right: 0px;
  }
  margin-right: 0px;
}



`;

const modalMenuStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0 auto',
    padding: '0',
    width: '100%',
    minHeight: '100%',
    marginRight: '-50%',
    transform: 'translate(-50%)'
  }
};

class SubdomainNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: '',

      prevScrollY: 0,
      showBanner: this.props.showBanner
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.checkActive = this.checkActive.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY <= 0 || this.state.prevScrollY > window.scrollY) {
      this.setState({ position: '', prevScrollY: window.scrollY });
    } else {
      this.setState({ position: 'overlaid', prevScrollY: window.scrollY });
    }
  };

  checkActive = (match, location) => {
    if (match) {
      if (!match.isExact) {
        return false;
      } else {
        return true;
      }
    }
  };

  render() {
    const subdomains = [
      {
        navItemLinkText: 'Tidepool.org',
        link: process.env.REACT_APP_LINK || 'https://tidepool.org',
        isActive: !process.env.SUBDOMAIN && !process.env.REACT_APP_SUBDOMAIN ? true : false
      },

      {
        navItemLinkText: 'Healthcare Professionals',
        link: process.env.REACT_APP_PROVIDER_LINK || 'https://provider.tidepool.org',
        isActive: process.env.SUBDOMAIN || process.env.REACT_APP_SUBDOMAIN ? true : false
      },

      { navItemLinkText: 'Support', link: 'https://support.tidepool.org', isActive: false }
    ];

    let { position } = this.state;
    let dropdownLinksToRender;

    if (this.props.entry) {
      dropdownLinksToRender = subdomains.map((subnavItem) => {
        return (
          <DropdownGroup1 className="group" key={subnavItem} px={3}>
            <TopNavigationLink
              className={subnavItem.isActive ? 'activeNavLink' : 'notActive'}
              activeClassName="activeNavLink"
              exact
              href={subnavItem.link ? subnavItem.link : ''}
            >
              {subnavItem.navItemLinkText}
            </TopNavigationLink>
          </DropdownGroup1>
        );
      });
    }

    return (
      <SubnavFullWidth showBanner={this.props.showBanner}>
        <SubnavWrapper className={position} showBanner={this.props.showBanner}>
          <Central bg="#fff" alignSelf="right" justifyContent="flex-end">
            {dropdownLinksToRender}
          </Central>

          <HeaderButtonGroup
            width={0.75}
            className="right-nav"
            mx={0}
            px={0}
            justifyContent="flex-end"
            alignItems="center"
          >
            <NavLinkGroups
              display={['none', 'none', 'block', 'block']}
              width={0.5}
              mx={0}
              px={0}
              justifyContent="flex-end"
              alignItems="center"
            />
            <ButtonContainer ml={3} className="Buttons">
              <Button
                mr={2}
                to="https://app.tidepool.org/signup"
                onClick={() => ReactGA.event({ category: 'click', action: 'Sign up patient', label: window.location.pathname })}
                className="small outline"
                text="Personal Sign Up"
              />
              <Button
                mr={2}
                to={process.env.REACT_APP_FORMSORT_CLINICIAN_SIGNUP_URL}
                onClick={() => ReactGA.event({ category: 'click', action: 'Sign up clinician', label: window.location.pathname })}
                className="small"
                text="Clinician Sign Up"
              />
              <Button
                pr={0}
                to="https://app.tidepool.org/login"
                onClick={() => ReactGA.event({ category: 'click', action: 'Log in', label: window.location.pathname })}
                className="small textUnderlined lastButton"
                text="Log In"
              />
            </ButtonContainer>
          </HeaderButtonGroup>
        </SubnavWrapper>
      </SubnavFullWidth>
    );
  }
}

SubdomainNav.propTypes = {};

SubdomainNav.defaultProps = {
  entry: {
    items: [{}]
  }
};

export default SubdomainNav;
