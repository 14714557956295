import React from 'react';
import { FadeIn } from 'animate-css-styled-components';
import Styled from 'styled-components';
import { Flex } from 'rebass';
import baseTheme from '../../themes/baseTheme';
import { DropdownGroupHeader, DropdownGroupHeaderSingle } from '../FontStyles';

import NavListItem from './NavListItem';

const OuterWrapper = Styled(Flex)`
align-items: center !important;
justify-content: center !important;
height: 100%;
white-space: nowrap;
`;

const Ul = Styled.ul`
padding: 0;
margin: 0;
list-style: none;
`;
const DropdownWrapper = Styled(Flex)`
border-radius: 4px;
left: 50%;
-webkit-transform: translateX(-50%);
transform: translateX(-50%)
top: 64px;
opacity: 1;
transition: all 0.15s ease-in-out;
text-align: center
position: absolute;
overflow: visible;
width: auto;
z-index: 12

&.open {
  height: auto;
}

`;

const DropdownArrow = Styled(Flex)`
    background-color: #fff;
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transform; rotate(45deg);
    overflow: hidden;
    border-left: ${baseTheme.border};
    border-top: ${baseTheme.border};
    z-index: 10;
`;

const DropdownInnerWrapper = Styled(Flex)`
border: ${baseTheme.border};
border-radius: 4px;
height: auto;
overflow: auto;
display: flex;
flex-direction: column;
min-width: 360px;

&:hover {

}


`;

const TopNavigationLink = Styled(DropdownGroupHeader)`
  padding: 16px;
  white-space: nowrap;
  background-color: #fff;
  cursor: pointer;
  color: ${baseTheme.colors.primaryFont}

  &.active-topnav {
   color: ${baseTheme.colors.linkFont} ;
  }
`;

const TopNavigationLinkSingle = Styled(DropdownGroupHeaderSingle)`
  padding: 16px;
  background-color: #fff;
  cursor: pointer;
  color: ${baseTheme.colors.primaryFont}

  &.active-topnav {
   color: ${baseTheme.colors.linkFont} ;
  }
`;

const DropdownGroup = Styled(Flex)`
display: flex;
flex-direction: column;
flex-wrap: wrap;
position: relative;
text-align: center;
overflow: visible;
height: 64px;
justify-content: center;
align-items: center;
z-index: 9999999;
`;

const Clipper = Styled(Flex)`
position: absolute;
top: -4;
left: 50%;
width: 19px;
height: 1px;
background-color: #fff;
z-index: 1985;
transform: translate(-50%)
`;

class NavigationDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.showDropdown = this.showDropdown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.hideDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.hideDropdown);
  }

  hideDropdown = () => {
    this.setState({ isOpen: false, active: false });
  };

  getItems = (dropdownGroup) => {
    let theLink;
    const dropdownItems = dropdownGroup.fields.subnavItems.map((item) => {
      if (item.sys.contentType) {
        if (item.fields.pageLinked) {
          theLink = item.fields.pageLinked.fields ? item.fields.pageLinked.fields.slug : '/';
          return (
            <li>
              <NavListItem
                to={theLink}
                activeClassName="active"
                includeAvatar="true"
                key={item.sys.id}
                entry={item}
                width={1}
                isNav
                secondary
              >
                {item.fields.navItemLinkText}
              </NavListItem>
            </li>
          );
        } else {
          theLink = item.fields.externalLink ? item.fields.externalLink : '/';
          return (
            <li onFocus={this.showDropdown}>
              <NavListItem
                external
                to={theLink}
                isNav
                secondary
                includeAvatar="true"
                key={item.sys.id}
                entry={item}
                width={1}
              >
                {item.fields.navItemLinkText}
              </NavListItem>
            </li>
          );
        }
      }
      return theLink;
    });

    return dropdownItems;
  };
  showDropdown = () => {
    this.setState({ isOpen: true, active: true });
  };

  render() {
    const { isOpen } = this.state;
    const dropdownGroup = this.props.headerComponent;

    const dropdownItems = this.getItems(dropdownGroup);
    return (
      <OuterWrapper className="OuterWrapper" alignItems="center" justifyContent="center">
        {dropdownGroup.fields.subnavItems[1] ? (
          <DropdownGroup onMouseEnter={this.showDropdown} onFocus={this.showDropdown} onMouseLeave={this.hideDropdown}>
            <TopNavigationLink
              className={this.props.activeMenu === dropdownGroup.fields.groupName ? 'active-topnav' : 'not-active'}
            >
              {dropdownGroup.fields.groupName}
            </TopNavigationLink>
            {isOpen && (
              <FadeIn timingFunction="ease-in-out" duration=".25s">
                <DropdownArrow className="dropdownArrow" />
                <DropdownWrapper className={isOpen ? 'open' : 'closed'}>
                  <Clipper />
                  <DropdownInnerWrapper bg="#fff" width={1}>
                    <Ul>{dropdownItems}</Ul>
                  </DropdownInnerWrapper>
                </DropdownWrapper>
              </FadeIn>
            )}
          </DropdownGroup>
        ) : (
          <DropdownGroup>
            <TopNavigationLinkSingle
              href={
                dropdownGroup.fields.subnavItems[0].fields.pageLinked
                  ? dropdownGroup.fields.subnavItems[0].fields.pageLinked.fields.slug
                  : dropdownGroup.fields.subnavItems[0].fields.externalLink
              }
              className={this.props.activeMenu === dropdownGroup.fields.groupName ? 'active' : 'not-active'}
            >
              {dropdownGroup.fields.groupName}
            </TopNavigationLinkSingle>
          </DropdownGroup>
        )}
      </OuterWrapper>
    );
  }
}

NavigationDropdown.propTypes = {};

NavigationDropdown.defaultProps = {
  headerComponent: {
    items: []
  }
};

export default NavigationDropdown;
