import { Display1, Subheading, Headline, Title, Body2 } from './components/FontStyles';
import MarkdownImage from './components/elements/MarkdownImage';
import DynamicLink from './components/elements/DynamicLink';

import baseTheme from './themes/baseTheme';

const Renderers = (theme = baseTheme) => ({
  overrides: {
    h1: {
      component: Display1,
      props: {
        className: '',
        as: 'h1'
      }
    },
    h2: {
      component: Headline,
      props: {
        className: '',
        as: 'h2',
        theme
      }
    },
    h3: {
      component: Title,
      props: {
        className: '',
        as: 'h3'
      }
    },
    h4: {
      component: Subheading
    },
    h5: {
      component: Subheading
    },
    h6: {
      component: Subheading
    },
    p: {
      component: Body2,
      props: {
        as: 'p',
        className: 'markdownBody2',
        theme
      }
    },
    span: {
      component: Body2,
      props: {
        as: 'p'
      }
    },
    li: {
      component: Body2,
      props: {
        as: 'li'
      }
    },
    a: {
      component: DynamicLink
    },
    img: {
      component: MarkdownImage
    }
  }
});

export default Renderers;
